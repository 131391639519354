<template>
  <div
    class="px-1.5 rounded-full text-xs font-bold uppercase tracking-wider mt-0.5 overflow-hidden"
    :class="tableStatus === 'Open' ? 'bg-green-600' : 'bg-black'"
  >
    <span class="animate-slide-down-fade-in-fast inline-block">
      {{ tableStatus }}
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  tableOpen: {
    type: Boolean,
    default: false,
  },
});

const tableStatus = computed(() => props.tableOpen ? 'Open' : 'Closed');
</script>
